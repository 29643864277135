<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: "dark:bg-gray-950",
  },
});

const route = useRoute();
const { $pwa } = useNuxtApp();
</script>

<template>
  <div class="h-screen flex items-center justify-center overlay">
    <div class="gradient" />
    <div class="absolute top-4 flex gap-4">
      <UButton v-if="route.path !== '/'" icon="i-heroicons-arrow-left" color="black" @click="navigateTo('/')" />
      <UButton v-if="!$pwa?.isPWAInstalled" icon="i-heroicons-arrow-down-tray" label="Descarca aplicatia" color="black"
        @click="$pwa?.install()" />
    </div>
    <NuxtPage />
  </div>
</template>

<style scoped>
.gradient {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: radial-gradient(50% 50% at 50% 50%,
      rgb(var(--color-primary-500) / 0.25) 0,
      #fff 100%);
}

.dark {
  .gradient {
    background: radial-gradient(50% 50% at 50% 50%,
        rgb(var(--color-primary-400) / 0.1) 0,
        rgb(var(--color-gray-950)) 100%);
  }
}

.overlay {
  background-size: 100px 100px;
  background-image: linear-gradient(to right,
      rgb(var(--color-gray-200)) 0.5px,
      transparent 0.5px),
    linear-gradient(to bottom,
      rgb(var(--color-gray-200)) 0.5px,
      transparent 0.5px);
}

.dark {
  .overlay {
    background-image: linear-gradient(to right,
        rgb(var(--color-gray-900)) 0.5px,
        transparent 0.5px),
      linear-gradient(to bottom,
        rgb(var(--color-gray-900)) 0.5px,
        transparent 0.5px);
  }
}
</style>
